.Wrapper {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Fullscreen {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
  }
}