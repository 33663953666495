.Menu {
  @media (max-width: 768px) { // Adjust the breakpoint as needed
    width: 100%;
    margin-top: 20px;
  }
  padding-left: 4px;
  padding-right: 4px;
}

.Link {
  color: inherit
}