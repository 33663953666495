@import "./scss/mq";
@import "./scss/variables";
@import "./fonts";

html.is-window {
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}

.opi-layout {
  min-height: 100vh !important;
}

body {
  margin: 0;
}

img {
  max-width: 100%;
}

.mb {
  margin-bottom: 24px !important;
}

.opi-avatar-string {
  font-size: 12px;
}

.opi-layout-footer {
  border-top: 1px solid map-get($grey, 200);
  background-color: map-get($grey, 100) !important;
  padding: 12px 24px !important;
  text-align: center;
  font-size: 12px !important;
}

.opi-btn-primary:disabled {
  border-color: rgba(118, 118, 118, 0.3) !important;
}

.full {
  width: 100%;
}

.error-list-errors .opi-form-item-explain-error {
  padding: $space / 4 $space / 2;
  background-color: map-get($red, 100);
  color: map-get($red, 500);
  text-align: center;
  margin-bottom: $space;
  border-radius: 4px;
  border: 1px solid map-get($red, 200);
}

.opi-breadcrumb-link a {
  transition: all 0.18s ease-in;

  &:hover,
  &:focus {
    background-color: $primaryBlueLight;
    color: $primaryBlue;
  }
}

.btn-as-link {
  padding: 0;
  height: auto;
}

.diff-html {
  width: 100%;

  &.diff-side-by-side {
    border: 1px solid map-get($grey, 400);
    margin-bottom: $space * 2;
    border-collapse: collapse;

    th,
    td {
      padding-left: $space / 3;
      padding-right: $space / 3;
    }

    thead th {
      height: 48px;
      text-align: left;
      background-color: map-get($grey, 400);
    }

    tbody {
      &.change tr {
        height: 48px;

        td,
        th {
          border: 1px solid map-get($grey, 400);
        }
      }

      &.skipped {
        display: none;
      }

      th {
        width: 80px;
        text-align: right;
        background-color: map-get($grey, 300);
      }

      .new,
      .old {
        width: 45%;
      }

      .new:not(.none) {
        background-color: map-get($green, 100);

        ins {
          text-decoration: none;
          background-color: map-get($green, 300);
        }
      }

      .old:not(.none) {
        background-color: map-get($red, 100);

        del {
          text-decoration: none;
          background-color: map-get($red, 200);
        }
      }
    }
  }
}

.scrollable-table {
  .opi-table-cell {
    white-space: nowrap;
    padding-top: 20 / 2;
    padding-bottom: 20 / 2;
  }
}

.opi-upload-drag-icon {
  margin: 0;
  font-size: $space * 2;
  color: map-get($primary, 500);
}

.opi-upload-text {
  margin: 0;
}

.no-m {
  margin: 0;
}

.select-min-width {
  min-width: 200px;
}

.opi-tag {
  border-radius: 5px;
}