@font-face {
  font-family: "Raleway";
  src: url('./fonts/raleway/Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: "Raleway";
  src: url('./fonts/raleway/SemiBold.ttf');
  font-weight: 500;
  font-style: normal;
}
  
@font-face {
  font-family: "Raleway";
  src: url('./fonts/raleway/Light.ttf');
  font-weight: 300;
  font-style: normal;
}
  
@font-face {
  font-family: "Raleway";
  src: url('./fonts/raleway/Bold.ttf');
  font-weight: 700;
  font-style: normal;
}