@import "../../styles/scss/variables";

.Layout {
  flex-direction: column;
}

.Content {
  background-color: map-get($grey, 100);
  border: 1px solid map-get($grey, 500);
}

.Header{
  width: 100%;
  height: 80px;
  background-color: white;
  border-bottom: 4px solid $primaryBlue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) { // Adjust the breakpoint as needed
      padding-inline: 0px;
      flex-direction: column;
      height: auto;
    }
}

.Image {
  width: 60px;
  height: auto;
}

.LogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}


.Btn {
  background-color: transparent !important;
  letter-spacing: .8px;
  text-transform: uppercase;
  font-weight: bold;

  &:hover,
  &:focus {
    border-bottom: 2px solid $primaryGreen !important;
  }
}